import React, { useContext, useState, useEffect } from "react";
import { useMatomo } from '@datapunt/matomo-tracker-react'
import classes from "./Team.module.css";
import Section from "../Components/Section";
import locale from "../locale.jsx";
import LangContext from "../Utils/Lang";
import Loader from "../Components/Loader";
import Axios from "axios";
import Modal from "../Components/Modal";
import clsx from "clsx";

const Team = () => {
  const { trackPageView, trackEvent } = useMatomo()
    // Track page view
    React.useEffect(() => {
        trackPageView()
    }, [])
  const compareObjects = (a, b) => {
      // Use toUpperCase() to ignore character casing
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
    
      let comparison = 0;
      if (nameA > nameB) {
        comparison = 1;
      } else if (nameA < nameB) {
        comparison = -1;
      }
      return comparison;
  }
  // const lang = useContext(LangContext);
  const lang = "ru";
  const l = locale[lang];
  const [modal, setModal] = useState({
    title: "",
    children: "",
    open: false
  })
  const [heads, setHeads] = useState({
    isFetching: true,
    data: {}
  })
  const [team, setTeam] = useState({
    isFetching: true,
    data: {}
  })
  const [founders, setFounders] = useState({
    isFetching: true,
    data: {}
  })
  const [probation, setProbation] = useState({
    isFetching: true,
    data: {}
  })
  const [honorable, setHonorable] = useState({
    isFetching: true,
    data: {}
  })
  const [exofficio, setExOfficio] = useState({
    isFetching: true,
    data: {}
  })
  useEffect(() => {
    Axios({
      url: "/data/heads.json",
      params: {
        timestamp: (new Date()) * 1
      }
    }).then((res) => {
      setHeads({
        isFetching: false,
        data: res.data
      })
    })
    Axios({
      url: "/data/founders.json",
      params: {
        timestamp: (new Date()) * 1
      }
    }).then((res) => {
      setFounders({
        isFetching: false,
        data: res.data
      })
    })
    Axios({
      url: "/data/team.json",
      params: {
        timestamp: (new Date()) * 1
      }
    }).then((res) => {
      setTeam({
        isFetching: false,
        data: res.data
      })
    })
    Axios({
      url: "/data/probation.json",
      params: {
        timestamp: (new Date()) * 1
      }
    }).then((res) => {
      setProbation({
        isFetching: false,
        data: res.data
      })
    })
    Axios({
      url: "/data/exofficio.json",
      params: {
        timestamp: (new Date()) * 1
      }
    }).then((res) => {
      setExOfficio({
        isFetching: false,
        data: res.data
      })
    })
    Axios({
      url: "/data/honorable.json",
      params: {
        timestamp: (new Date()) * 1
      }
    }).then((res) => {
      setHonorable({
        isFetching: false,
        data: res.data
      })
    })
  }, [])
  const openModal = (member) => (
    trackEvent({ category: 'team', action: 'opened-modal', name: member.name}),
    setModal({
      title: member.name,
      children: (
        <div className={classes.Member}>
          <div style={{
            backgroundImage: `url(${member.img})`
          }} className={classes.memberPhoto} />
          <h3>{member.name}</h3>
          <div dangerouslySetInnerHTML={{__html: member.info }} />
          
        </div>
      ),
      open: true
    })
  )
  const closeModal = () => (
    setModal({
      ...modal,
      open: false
    })
  )
  return (
    <div className={classes.Team}>
      {heads.isFetching ? (<Loader />) : heads.data[lang].map((section, i) => (
        <Section title={section.title} key={i}>
          {section.about ? <p>{section.about}</p> : null}
          <div className={classes.members}>
            {section.list.map((el, i) => (
              <Member onClick={openModal} member={el} key={i} />
            ))}
          </div>
        </Section>
      ))}
      {team.isFetching ? (<Loader />) : team.data[lang].map((section, i) => (
        <Section title={section.title} key={i}>
          <div className={clsx(classes.members, classes.membersSmall)}>
            {section.list.map((el, i) => (
              <Member onClick={openModal} member={el} key={i} />
            ))}
          </div>
        </Section>
      ))}
      {probation.isFetching ? (<Loader />) : probation.data[lang].map((section, i) => (
        <Section title={section.title} key={i}>
          <div className={clsx(classes.members, classes.membersSmall)}>
            {section.list.sort(compareObjects).map((el, i) => (
              <PreMember member={el} key={i} />
            ))}
          </div>
        </Section>
      ))}
      {honorable.isFetching ? (<Loader />) : honorable.data[lang].map((section, i) => (
        <Section title={section.title} key={i}>
          <div className={clsx(classes.members, classes.membersSmall)}>
            {section.list.sort(compareObjects).map((el, i) => (
              <Member member={el} key={i} />
            ))}
          </div>
        </Section>
      ))}
      {exofficio.isFetching ? (<Loader />) : exofficio.data[lang].map((section, i) => (
        <Section title={section.title} key={i}>
          <div className={clsx(classes.members, classes.membersSmall)}>
            {section.list.sort(compareObjects).map((el, i) => (
              <ExMember member={el} key={i} />
            ))}
          </div>
        </Section>
      ))}
      <Section big title={l.team.t1}>
        <p>{l.team.p1}</p>
        {founders.isFetching ? (<Loader />) : (
          <div className={classes.members}>
            {founders.data[lang].map((el, i) => (
              <Member onClick={openModal} subtitle={false} member={el} key={i} />
            ))}
          </div>
        )}
      </Section>
      <Modal close={closeModal} open={modal.open} title={modal.title}>{modal.children}</Modal>
    </div>
  );
};

const Member = ({ member, subtitle = true, onClick }) => (
  <div className={classes.Member}>
    <div onClick={() => onClick(member)} style={{
      backgroundImage: `url(${member.img})`
    }} className={classes.memberPhoto} />
    <h3>{member.name}</h3>
    {subtitle ? <div className={classes.memberSubtitle}>{member.subtitle}</div> : null}
    <div className={classes.memberActions}>
      <button onClick={() => onClick(member)} className={classes.info}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg></button>
      {member.email ? <a className={classes.memberEmail} href={`mailto:${member.email}`}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg></a> : null}
    </div>
  </div>
);

const PreMember = ({ member, subtitle = true }) => (
  <div className={classes.member}>
    <h3>{member.name}</h3>
    {subtitle ? <div className={classes.memberSubtitle}> {member.subtitle}</div> : null}
  </div>
)

const ExMember = ({ member, years = true, impact = true }) => (
  <div className={classes.member}>
    <h4>{member.name}</h4>
    {/* {impact ? <div> {member.impact}</div> : null} */}
    {/* {years ? <div className={classes.memberCur}> ({member.years})</div> : null} */}
  </div>
)

export default Team;
